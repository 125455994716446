import KothHighlight from "../KothHighlight/KothHighlight";
import KothTable from "../KothTable/KothTable";
import styles from "./KothStats.module.css";
import { useEffect, useState } from "react";

const KothStats = (params) => {
    const { name } = params;

    /* const koth = {
        territory: "Tanglewood",
        startDate: "01/05/2024 om 20:00",
        highlights: {
            "topKills": "Twinten",
            "topCaptures": "M4NDSr",
            "topDamage": "arreman",
            "topRegen": "Koboeshka"
        },
        statistics: [
            {
                "playerName": "M4NDSr",
                "kingdom": "Ethereal",
                "alliance": "Ember Legion",
                "damage": 1000,
                "regen": 1000,
                "kills": 1,
                "deaths": 1,
                "captures": 1000,
            },
            {
                "playerName": "Koboeshka",
                "kingdom": "A kingdom",
                "alliance": "An alliance",
                "damage": 0,
                "regen": 2000,
                "kills": 5,
                "deaths": 0,
                "captures": 100,
            }
        ]
    } */
    
    const [koth, setKoth] = useState(null);

    useEffect(() => {
        const fetchKothData = async () => {
            try {
                const response = await fetch('https://api.majestumkingdoms.nl/api/koths?name=' + name);
                //const response = await fetch('https://localhost:44390/api/koths?name=' + name);
                if (!response.ok) {
                    throw new Error('Failed to fetch koth data');
                }
                const jsonData = await response.json();
                setKoth(jsonData);
            } catch (error) {
                console.error('Error fetching player data:', error);
                setKoth({
                    territory: null
                });
            }
        };

        fetchKothData();
    }, [name]);

    if (!koth) {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                <p>Loading koth data...</p>
            </div>
        );
    }

    return (
        <div>
            {
                koth.territory != null ? (
                    <>
                        <h1 className={styles.kothTitle}>KOTH event: {koth.territory}</h1>
                        <p><em>Gestart op {koth.startDate}</em></p>

                        <h2 className={styles.title}>Highlights</h2>
                        <div className={styles.highlights}>
                            <KothHighlight highlight="Meeste spelers gekilled" name={koth.highlights.topKills} />
                            <KothHighlight highlight="Meeste capture points" name={koth.highlights.topCaptures} />
                            <KothHighlight highlight="Meeste player damage" name={koth.highlights.topDamage} />
                            <KothHighlight highlight="Meeste health regen" name={koth.highlights.topRegen} />
                        </div>

                        <h2 className={styles.title}>Statistics</h2>
                        <div className={styles.statistics}>
                            <KothTable data={koth.statistics} />
                        </div>
                    </>
                ) : (
                    <>
                        <h1 className={styles.kothTitle}>KOTH event niet gevonden.</h1>
                    </>
                )
            }
        </div>
    );
};

export default KothStats;