import { useEffect, useState } from "react";
import styles from "./KothWinnerViewer.module.css";

const ImageMapping = {
    "sylvanenclave": "/images/sylvan_enclave.png",
    "emberlegion": "/images/ember_legion.png",
    "duskvanguard": "/images/dusk_vanguard.png"
};

const NameMapping = {
    "sylvanenclave": "Sylvan Enclave",
    "emberlegion": "Ember Legion",
    "duskvanguard": "Dusk Vanguard"
};

const WinnerViewer = (props) => {
    const { name } = props;

    const [winner, setWinner] = useState(null);

    useEffect(() => {
        const fetchWinner = async () => {
            try {
                const response = await fetch('https://api.majestumkingdoms.nl/api/koths/winner?name=' + name);
                //const response = await fetch('https://localhost:44390/api/koths/winner?name=' + name);
                if (!response.ok) {
                    throw new Error('Failed to fetch koth winner');
                }
                const jsonData = await response.json();
                setWinner(jsonData.name);
            } catch (error) {
                console.error('Error fetching koth winner data:', error);
                setWinner("none");
            }
        };

        fetchWinner();
    }, [name]);
    
    /* const winner = "sylvanenclave"; */

    if (!winner) {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                <p>Loading koth winner...</p>
            </div>
        );
    }

    if (winner === "none") {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-xmark"></i>
                <p>Geen winnaar gevonden</p>
            </div>
        );
    }

    return (
        <div className={styles.kothWinnerViewer}>
            <img src={ImageMapping[winner]} alt={winner} />
            <div>Winnaar:</div>
            <div>{NameMapping[winner]}</div>
        </div>
    );
};

export default WinnerViewer;