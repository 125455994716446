import WarHighlight from "../WarHighlight/WarHighlight";
import WarTable from "../WarTable/WarTable";
import styles from "./WarStats.module.css";
import { useEffect, useState } from "react";

const WarStats = (params) => {
    const { name } = params;

    /* const war = {
        territory: "Tanglewood",
        startDate: "01/05/2024 om 20:00",
        highlights: {
            "topKills": "Twinten",
            "topCaptures": "M4NDSr",
            "topDamage": "arreman",
            "topRegen": "Koboeshka"
        },
        statistics: [
            {
                "playerName": "M4NDSr",
                "kingdom": "Ethereal",
                "alliance": "Ember Legion",
                "damage": 1000,
                "regen": 1000,
                "kills": 1,
                "deaths": 1,
                "captures": 1000,
            },
            {
                "playerName": "Koboeshka",
                "kingdom": "A kingdom",
                "alliance": "An alliance",
                "damage": 0,
                "regen": 2000,
                "kills": 5,
                "deaths": 0,
                "captures": 100,
            }
        ]
    } */
    
    const [war, setWar] = useState(null);

    useEffect(() => {
        const fetchWarData = async () => {
            try {
                const response = await fetch('https://api.majestumkingdoms.nl/api/wars?name=' + name);
                //const response = await fetch('https://localhost:44390/api/wars?name=' + name);
                if (!response.ok) {
                    throw new Error('Failed to fetch war data');
                }
                const jsonData = await response.json();
                setWar(jsonData);
            } catch (error) {
                console.error('Error fetching war data:', error);
                setWar({
                    territory: null
                });
            }
        };

        fetchWarData();
    }, [name]);

    if (!war) {
        return (
            <div className={styles.loading}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                <p>Loading war data...</p>
            </div>
        );
    }

    return (
        <div>
            {
                war.territory != null ? (
                    <>
                        <h1 className={styles.warTitle}>Siege war: {war.territory}</h1>
                        <p><em>Gestart op {war.startDate}</em></p>

                        <h2 className={styles.title}>Highlights</h2>
                        <div className={styles.highlights}>
                            <WarHighlight highlight="Meeste spelers gekilled" name={war.highlights.topKills} />
                            <WarHighlight highlight="Meeste capture points" name={war.highlights.topCaptures} />
                            <WarHighlight highlight="Meeste player damage" name={war.highlights.topDamage} />
                            <WarHighlight highlight="Meeste health regen" name={war.highlights.topRegen} />
                        </div>

                        <h2 className={styles.title}>Statistics</h2>
                        { war.statistics.length > 0 ? (
                                <div className={styles.statistics}>
                                    <WarTable data={war.statistics} />
                                </div>
                            ) : (
                                <div></div>
                            ) 
                        }
                    </>
                ) : (
                    <>
                        <h1 className={styles.warTitle}>War niet gevonden.</h1>
                    </>
                )
            }
        </div>
    );
};

export default WarStats;